<template>
  <div>
    <section class="section">
      <div class="container is-max-desktop">
        <h3 class="title is-3">Verifikation</h3>
        <div class="content is-large">
          <p>Verifizieren Sie Ihre Mobilnummer:</p>
          <p>
            <b-tag type="is-light" size="is-large">{{ numberToVerify }}</b-tag>
          </p>
          <div class="buttons">
            <button
              class="button is-large is-primary"
              @click="submitVerification"
            >
              Jetzt verifizieren
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { verifyMobileNumber } from "@/webapi";
export default {
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    message_id: {
      required: true,
      type: String,
    },
    project_id: {
      required: true,
      type: String,
    },
  },
  methods: {
    async submitVerification() {
      this.$parent.is_loading = true;
      let verificationResponse = await verifyMobileNumber(
        this.project_id,
        this.message_id
      );
      if(verificationResponse.status == 200 && verificationResponse.data.state) {
        this.$parent.setFinished();
      } else {
        this.$buefy.dialog.alert({
          title: "Fehler aufgetreten",
          message: "Bitte versuchen Sie es später erneut.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      this.$parent.is_loading = false;
    },
  },
  computed: {
    numberToVerify() {
      return this.messageData.data.number;
    },
  },
};
</script>