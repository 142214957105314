<template>
  <div>
    <section class="section">
      <div class="container is-max-desktop">
        <h3 class="title is-3">{{ textVal("CHECK_DATE_TITLE") }}</h3>
        <div class="content is-large">
          <p>{{ textVal("CHECK_DATE_SUBTITLE") }}</p>
          <p>
            <b-taglist>
              <b-tag type="is-dark" size="is-large">{{
                textVal("CHECK_DATE_BOXES", units)
              }}</b-tag>
              <b-tag type="is-info" size="is-large">{{ rangeText }}</b-tag>
            </b-taglist>
          </p>
          <div class="notification">
            <h4 class="title is-4">{{ textVal("CHECK_DATE_PICKUP_TIME") }}</h4>
            <CheckDateEntry
              v-for="(entryDate, index) in pickup_dates"
              :key="index"
              :dateVal="entryDate"
              :updateDateEntry="updateDateVal"
              :dateLocaleCode="dateLocaleCode"
            />
          </div>

          <div class="notification">
            <h4 class="title is-4">{{ textVal("CHECK_DATE_RETURN_TIME") }}</h4>
            <CheckDateEntry
              v-for="(entryDate, index) in return_dates"
              :key="index"
              :dateVal="entryDate"
              :updateDateEntry="updateDateVal"
              :dateLocaleCode="dateLocaleCode"
            />
          </div>

          <b-field :label="textVal('CHECK_DATE_NOTE')">
            <b-input
              maxlength="200"
              type="textarea"
              rows="4"
              v-model="preferences.comment"
            ></b-input>
          </b-field>

          <div class="buttons">
            <b-button
              :label="textVal('CHECK_DATE_SUBMIT')"
              type="is-info"
              size="is-medium"
              class="is-fullwidth"
              @click="openConfirmDialog"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CheckDateEntry from "./CheckDateEntry.vue";
import { updatePickupMessage } from "@/webapi";
import {
  CHECK_DATE_MODAL_CANCEL,
  CHECK_DATE_MODAL_SUBMIT,
  CHECK_DATE_MODAL_TEXT,
  CHECK_DATE_MODAL_TITLE,
  getCheckDateText,
} from "./pickup_text";
export default {
  components: {
    CheckDateEntry,
  },
  props: {
    messageData: {
      required: true,
      type: Object,
    },
    message_id: {
      required: true,
      type: String,
    },
    project_id: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      pickup_dates: null,
      return_dates: null,
      preferences: {
        selection: [],
        comment: null,
      },
    };
  },
  created() {
    this.generateDateRanges();
  },
  methods: {
    generateDateRanges() {
      this.pickup_dates = this.getDaysArray(
        this.pickup_date_from,
        this.pickup_date_to
      );
      this.return_dates = this.getDaysArray(
        this.return_date_from,
        this.return_date_to
      );
    },
    getDaysArray(start, end) {
      for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    },
    updateDateVal(dateTarget, dateVal) {
      this.preferences.selection = this.preferences.selection.filter(
        (obj) => obj.date != dateTarget
      );
      this.preferences.selection.push({
        date: dateTarget,
        val: dateVal,
      });
    },
    openConfirmDialog() {
      this.$buefy.dialog.confirm({
        type: "is-info",
        title: this.textVal(CHECK_DATE_MODAL_TITLE),
        message: this.textVal(CHECK_DATE_MODAL_TEXT),
        confirmText: this.textVal(CHECK_DATE_MODAL_SUBMIT),
        cancelText: this.textVal(CHECK_DATE_MODAL_CANCEL),
        onConfirm: () => this.submitCheckDateInputs(),
      });
    },
    async submitCheckDateInputs() {
      this.$parent.is_loading = true;
      let updateResponseState = await updatePickupMessage(
        this.project_id,
        this.message_id,
        this.preferences
      );
      if (updateResponseState.data.state) {
        this.$parent.setFinished();
      } else {
        this.$buefy.dialog.alert({
          title: "An error occured",
          message: "Please try again later.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      this.$parent.is_loading = false;
    },
    textVal(textId, params) {
      return getCheckDateText(this.languageCode, textId, params);
    },
  },
  computed: {
    pickup_date_from() {
      return new Date(this.messageData.data.ranges.pickup_date_from);
    },
    pickup_date_to() {
      return new Date(this.messageData.data.ranges.pickup_date_to);
    },
    return_date_from() {
      return new Date(this.messageData.data.ranges.return_date_from);
    },
    return_date_to() {
      return new Date(this.messageData.data.ranges.return_date_to);
    },
    units() {
      return this.messageData.data.units;
    },
    rangeText() {
      return `${this.pickup_date_to.toLocaleDateString(
        "de-DE"
      )} - ${this.return_date_from.toLocaleDateString("de-DE")}`;
    },
    dateLocaleCode() {
      return this.$parent.getDateLocaleCode();
    },
    languageCode() {
      return this.$parent.getLanguageCode();
    },
  },
};
</script>