<template>
  <div>
    <section class="section">
      <div class="container is-max-desktop">
        <h3 class="title is-3">{{textVal('GENERAL_THANKS')}}</h3>
        <div class="content is-large">
          <p>{{ subtitle }}</p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {
  GENERAL_DATE_CHECK,
  GENERAL_FIXATION_CHECK,
  GENERAL_NUMBER_VERIFIED,
  getGeneralText,
} from "./pickup_text";
export default {
  props: {
    message_type: {
      type: String,
      required: true,
    },
  },
  methods: {
    textVal(textId) {
      return getGeneralText(this.$parent.getLanguageCode(), textId);
    },
  },
  computed: {
    subtitle() {
      const languageCode = this.$parent.getLanguageCode();
      switch (this.message_type) {
        case "verify_number":
          return getGeneralText(languageCode, GENERAL_NUMBER_VERIFIED);
        case "date_check":
          return getGeneralText(languageCode, GENERAL_DATE_CHECK);
        case "fixation_check":
          return getGeneralText(languageCode, GENERAL_FIXATION_CHECK);
        default:
          return "";
      }
    },
  },
};
</script>