<template>
  <div>
    <section class="section">
      <div class="container is-max-desktop">
        <h3 class="title is-3">{{ textVal("FIXATION_CHECK_TITLE") }}</h3>
        <div class="content is-large">
          <p>{{ textVal("FIXATION_CHECK_SUBTITLE") }}</p>
          <div class="notification">
            <h4 class="title is-4">
              {{ textVal("CONFIRMATION_PICKUP_TIME") }}
            </h4>
            <b-taglist>
              <b-tag type="is-info" size="is-large">{{
                messageData.data.pickup_time
              }}</b-tag>
              <b-tag type="is-dark" size="is-large">{{ pickup_date }}</b-tag>
            </b-taglist>
            <b-radio-button
              v-model="pickup_verified"
              :native-value="true"
              type="is-success is-light is-outlined"
            >
              <b-icon icon="check"></b-icon>
              <span>{{ textVal("FIXATION_CHECK_ACCEPT") }}</span>
            </b-radio-button>
            <b-radio-button
              v-model="pickup_verified"
              :native-value="false"
              type="is-danger is-light is-outlined"
            >
              <b-icon icon="close"></b-icon>
              <span>{{ textVal("FIXATION_CHECK_DECLINE") }}</span>
            </b-radio-button>
            <hr style="border-top: 1px dotted black" />
            <h4 class="title is-4">
              {{ textVal("CONFIRMATION_RETURN_TIME") }}
            </h4>
            <b-taglist>
              <b-tag type="is-info" size="is-large">{{
                messageData.data.return_time
              }}</b-tag>
              <b-tag type="is-dark" size="is-large">{{ return_date }}</b-tag>
            </b-taglist>
            <b-radio-button
              v-model="return_verified"
              :native-value="true"
              type="is-success is-light is-outlined"
            >
              <b-icon icon="check"></b-icon>
              <span>{{ textVal("FIXATION_CHECK_ACCEPT") }}</span>
            </b-radio-button>
            <b-radio-button
              v-model="return_verified"
              :native-value="false"
              type="is-danger is-light is-outlined"
            >
              <b-icon icon="close"></b-icon>
              <span>{{ textVal("FIXATION_CHECK_DECLINE") }}</span>
            </b-radio-button>
            <div v-if="messageData.data.comment">
              <hr style="border-top: 1px dotted black" />
              <h4 class="title is-4">{{ textVal("CHECK_DATE_NOTE") }}</h4>
              <p>
                <i>{{ messageData.data.comment }}</i>
              </p>
            </div>
          </div>

          <div>
            <b-field :label="textVal('FIXATION_CHECK_RESPONSE')">
              <b-input
                maxlength="200"
                type="textarea"
                rows="4"
                v-model="comment"
              ></b-input>
            </b-field>
            <div class="buttons">
              <b-button
                :label="textVal('FIXATION_CHECK_SUBMIT')"
                type="is-info"
                size="is-medium"
                class="is-fullwidth"
                @click="openConfirmDialog"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { updatePickupMessage } from "@/webapi";
import {
  getFixationCheckText,
  getConfirmationText,
  getCheckDateText,
  CHECK_DATE_MODAL_TITLE,
  CHECK_DATE_MODAL_TEXT,
  CHECK_DATE_MODAL_SUBMIT,
  CHECK_DATE_MODAL_CANCEL,
} from "./pickup_text";
export default {
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    message_id: {
      required: true,
      type: String,
    },
    project_id: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      // todo: checkbox mit ok für zeitpunkt?
      comment: null,
      pickup_verified: true,
      return_verified: true,
    };
  },
  computed: {
    pickup_date() {
      let pickupDate = new Date(this.messageData.data.pickup_date);
      return this.formatDate(pickupDate);
    },
    return_date() {
      return this.formatDate(new Date(this.messageData.data.return_date));
    },
  },
  methods: {
    formatDate(date) {
      const languageCode = this.$parent.getLanguageCode();
      return `${date.toLocaleDateString(languageCode, {
        weekday: "long",
      })}, ${date.toLocaleDateString("de-DE")}`;
    },
    openConfirmDialog() {
      this.$buefy.dialog.confirm({
        type: "is-info",
        title: this.textVal(CHECK_DATE_MODAL_TITLE),
        message: this.textVal(CHECK_DATE_MODAL_TEXT),
        confirmText: this.textVal(CHECK_DATE_MODAL_SUBMIT),
        cancelText: this.textVal(CHECK_DATE_MODAL_CANCEL),
        onConfirm: () => this.submitFixationInputs(),
      });
    },
    async submitFixationInputs() {
      this.$parent.is_loading = true;
      let responseData = {
        comment: this.comment,
        pickup_verified: this.pickup_verified,
        return_verified: this.return_verified,
      };
      let updateResponseState = await updatePickupMessage(
        this.project_id,
        this.message_id,
        responseData
      );
      if (updateResponseState.data.state) {
        this.$parent.setFinished();
      } else {
        this.$buefy.dialog.alert({
          title: "An error occured",
          message: "Please try again later.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      this.$parent.is_loading = false;
    },
    textVal(textId, params) {
      let textValue = getFixationCheckText(
        this.$parent.getLanguageCode(),
        textId,
        params
      );
      if (!textValue) {
        textValue = getConfirmationText(
          this.$parent.getLanguageCode(),
          textId,
          params
        );
      }
      if (!textValue) {
        textValue = getCheckDateText(
          this.$parent.getLanguageCode(),
          textId,
          params
        );
      }
      return textValue;
    },
  },
};
</script>