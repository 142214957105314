<template>
  <section class="section">
    <div class="content is-large">
      <h2 class="title is-2">{{ textVal("PORTAL_SETTINGS_TAB") }}</h2>
      <div v-if="prefs">
        <b-field>
          <b-switch
            v-model="prefs.reminder_enabled"
            @input="updatePrefsAfterChange"
          >
            {{ textVal("PORTAL_SETTING_REMINDER") }}
          </b-switch>
        </b-field>
      </div>
    </div>
  </section>
</template>
<script>
import { updatePickupPrefs } from "@/webapi";
import { getPortalText } from "./pickup_text";
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
    pickup_id: {
      type: String,
      required: true,
    },
    language_code: {
      type: String,
      required: true,
      default: "en",
    },
  },
  data: function () {
    return {
      prefs: null,
    };
  },
  mounted() {
    this.prefs = this.location.prefs;
    if (!this.prefs) {
      this.prefs = {
        reminder_enabled: true,
        // todo: mehr prefs?
      };
    }
  },
  methods: {
    async updatePrefsAfterChange() {
      let updateResponse = await updatePickupPrefs(this.pickup_id, this.prefs);
      if (updateResponse.status != 200 || !updateResponse.data.state) {
        alert("An error occured. Please try again later.");
      }
      this.$parent.refreshData();
    },
    textVal(textId, params) {
      return getPortalText(this.language_code, textId, params);
    },
  },
};
</script>