// todo: nationalization for pickup portal

export const LANGUAGE_CODE_DE = 'de'
export const LANGUAGE_CODE_IT = 'it'
export const LANGUAGE_CODE_FR = 'fr'
export const LANGUAGE_CODE_EN = 'en'

export function getDateCode(langCode) {
    switch (langCode) {
        case LANGUAGE_CODE_DE:
            return 'de-DE'
        case LANGUAGE_CODE_EN:
        default:
            return 'en-GB'
    }
}


/* CONFIRMATION START */
export const CONFIRMATION_TITLE = 'CONFIRMATION_TITLE'
export const CONFIRMATION_SUBTITLE = 'CONFIRMATION_SUBTITLE'
export const CONFIRMATION_DOMINO_COUNT = 'CONFIRMATION_DOMINO_COUNT'
export const CONFIRMATION_DOMINO_COUNT_VALUE = 'CONFIRMATION_DOMINO_COUNT_VALUE'
export const CONFIRMATION_PICKUP_TIME = 'CONFIRMATION_PICKUP_TIME'
export const CONFIRMATION_RETURN_TIME = 'CONFIRMATION_RETURN_TIME'
export const CONFIRMATION_LOCATION = 'CONFIRMATION_LOCATION'
export const CONFIRMATION_SHOW_ON_MAP = 'CONFIRMATION_SHOW_ON_MAP'
export const CONFIRMATION_CONTACT = 'CONFIRMATION_CONTACT'

export function getConfirmationText(langCode, textId, params) {
    switch (textId) {
        case CONFIRMATION_TITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Miete'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Rent'
            }
        case CONFIRMATION_SUBTITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Alle Informationen zu Ihrer Ausleihe.'
                case LANGUAGE_CODE_EN:
                default:
                    return 'All information about your rent.'
            }
        case CONFIRMATION_DOMINO_COUNT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Anzahl Dominosteine'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Number of dominos'
            }
        case CONFIRMATION_DOMINO_COUNT_VALUE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `${params[0]} Kisten (${params[1]} Dominos)`
                case LANGUAGE_CODE_EN:
                default:
                    return `${params[0]} boxes (${params[1]} dominos)`
            }
        case CONFIRMATION_PICKUP_TIME:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Abholzeitpunkt'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Pickup time'
            }
        case CONFIRMATION_RETURN_TIME:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Rückgabezeitpunkt'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Return time'
            }
        case CONFIRMATION_LOCATION:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Standort'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Location'
            }
        case CONFIRMATION_CONTACT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Kontakt'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Contact'
            }
        case CONFIRMATION_SHOW_ON_MAP:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Auf Karte anzeigen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Show on map'
            }
    }
}

/* CONFIRMATION END */

/* CHECK DATE START */

export const CHECK_DATE_TITLE = 'CHECK_DATE_TITLE'
export const CHECK_DATE_SUBTITLE = 'CHECK_DATE_SUBTITLE'
export const CHECK_DATE_BOXES = 'CHECK_DATE_BOXES'
export const CHECK_DATE_PICKUP_TIME = 'CHECK_DATE_PICKUP_TIME'
export const CHECK_DATE_RETURN_TIME = 'CHECK_DATE_RETURN_TIME'
export const CHECK_DATE_NOTE = 'CHECK_DATE_NOTE'
export const CHECK_DATE_SUBMIT = 'CHECK_DATE_SUBMIT'
export const CHECK_DATE_MODAL_TITLE = 'CHECK_DATE_MODAL_TITLE'
export const CHECK_DATE_MODAL_TEXT = 'CHECK_DATE_MODAL_TEXT'
export const CHECK_DATE_MODAL_CANCEL = 'CHECK_DATE_MODAL_CANCEL'
export const CHECK_DATE_MODAL_SUBMIT = 'CHECK_DATE_MODAL_SUBMIT'
export const CHECK_DATE_MORNING = 'CHECK_DATE_MORNING'
export const CHECK_DATE_AFTERNOON = 'CHECK_DATE_AFTERNOON'
export const CHECK_DATE_EVENING = 'CHECK_DATE_EVENING'

export function getCheckDateText(langCode, textId, params) {
    switch (textId) {
        case CHECK_DATE_MORNING:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Vormittag'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Morning'
            }
        case CHECK_DATE_AFTERNOON:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Nachmittag'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Afternoon'
            }
        case CHECK_DATE_EVENING:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Abend'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Evening'
            }
        case CHECK_DATE_PICKUP_TIME:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Abholzeitraum'
                case LANGUAGE_CODE_EN:
                default:
                    return 'pickup time range'
            }
        case CHECK_DATE_RETURN_TIME:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Rückgabezeitraum'
                case LANGUAGE_CODE_EN:
                default:
                    return 'return time range'
            }
        case CHECK_DATE_TITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Zeitraum prüfen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'check time range'
            }
        case CHECK_DATE_SUBTITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Bitte geben Sie uns Ihre Angaben an.'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Please choose the suitable time ranges.'
            }
        case CHECK_DATE_BOXES:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `${params} Dominokisten`
                case LANGUAGE_CODE_EN:
                default:
                    return `${params} boxes`
            }
        case CHECK_DATE_NOTE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `Bemerkung`
                case LANGUAGE_CODE_EN:
                default:
                    return `additional information`
            }
        case CHECK_DATE_SUBMIT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `Rückmeldung einreichen`
                case LANGUAGE_CODE_EN:
                default:
                    return `submit`
            }
        case CHECK_DATE_MODAL_TITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `Rückmeldung einreichen`
                case LANGUAGE_CODE_EN:
                default:
                    return `Submit information`
            }
        case CHECK_DATE_MODAL_TEXT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `Möchten Sie Ihre Angaben einreichen?`
                case LANGUAGE_CODE_EN:
                default:
                    return `Do you want to submit your data?`
            }
        case CHECK_DATE_MODAL_CANCEL:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `Abbrechen`
                case LANGUAGE_CODE_EN:
                default:
                    return `cancel`
            }
        case CHECK_DATE_MODAL_SUBMIT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return `Jetzt einreichen`
                case LANGUAGE_CODE_EN:
                default:
                    return `submit now`
            }
    }
}

/* CHECK DATE END */

/* PORTAL START */

export const PORTAL_RENT_TAB = 'PORTAL_RENT_TAB'
export const PORTAL_SETTINGS_TAB = 'PORTAL_SETTINGS_TAB'
export const PORTAL_BOXES = 'PORTAL_BOXES'
export const PORTAL_CONTACT = 'PORTAL_CONTACT'
export const PORTAL_PHONE = 'PORTAL_PHONE'
export const PORTAL_RENT = 'PORTAL_RENT'
export const PORTAL_SETTING_REMINDER = 'PORTAL_SETTING_REMINDER'

export function getPortalText(langCode, textId, params) {
    switch (textId) {
        case PORTAL_RENT_TAB:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Vermietungen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'rents'
            }
        case PORTAL_RENT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Vermietung'
                case LANGUAGE_CODE_EN:
                default:
                    return 'rent'
            }
        case PORTAL_SETTINGS_TAB:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Einstellungen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'settings'
            }
        case PORTAL_BOXES:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Kisten'
                case LANGUAGE_CODE_EN:
                default:
                    return 'boxes'
            }
        case PORTAL_CONTACT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Kontaktperson'
                case LANGUAGE_CODE_EN:
                default:
                    return 'contact'
            }
        case PORTAL_PHONE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Telefon'
                case LANGUAGE_CODE_EN:
                default:
                    return 'phone'
            }
        case PORTAL_SETTING_REMINDER:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Reminder-Benachrichtigung'
                case LANGUAGE_CODE_EN:
                default:
                    return 'notification reminder'
            }
    }
}

/* PORTAL END */

/* FIXATION CHECK START */

export const FIXATION_CHECK_TITLE = 'FIXATION_CHECK_TITLE'
export const FIXATION_CHECK_SUBTITLE = 'FIXATION_CHECK_SUBTITLE'
export const FIXATION_CHECK_ACCEPT = 'FIXATION_CHECK_ACCEPT'
export const FIXATION_CHECK_DECLINE = 'FIXATION_CHECK_DECLINE'
export const FIXATION_CHECK_RESPONSE = 'FIXATION_CHECK_RESPONSE'
export const FIXATION_CHECK_SUBMIT = 'FIXATION_CHECK_SUBMIT'

export function getFixationCheckText(langCode, textId, params) {
    switch (textId) {
        case FIXATION_CHECK_TITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Zeitpunkt prüfen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'check appointments'
            }
        case FIXATION_CHECK_SUBTITLE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Bitte geben Sie uns Ihre Angaben an.'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Please select the options.'
            }
        case FIXATION_CHECK_ACCEPT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Bestätigen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Accept'
            }
        case FIXATION_CHECK_DECLINE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Ablehnen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Decline '
            }
        case FIXATION_CHECK_RESPONSE:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Rückmeldung'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Response'
            }
        case FIXATION_CHECK_SUBMIT:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Rückmeldung einreichen'
                case LANGUAGE_CODE_EN:
                default:
                    return 'submit'
            }
    }
}

/* FIXATION CHECK END */

/* GENERAL START */

export const GENERAL_HELP = 'GENERAL_HELP'
export const GENERAL_NUMBER_VERIFIED = 'GENERAL_NUMBER_VERIFIED'
export const GENERAL_DATE_CHECK = 'GENERAL_DATE_CHECK'
export const GENERAL_FIXATION_CHECK = 'GENERAL_FIXATION_CHECK'
export const GENERAL_THANKS = 'GENERAL_THANKS'

export function getGeneralText(langCode, textId, params) {
    switch (textId) {
        case GENERAL_HELP:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Bei Fragen oder Unklarheiten '
                case LANGUAGE_CODE_EN:
                default:
                    return 'In case of questions '
            }
        case GENERAL_NUMBER_VERIFIED:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Ihre Nummer wurde erfolgreich verifiziert.'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Your phone number has been verified.'
            }
        case GENERAL_DATE_CHECK:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Ihre Angaben wurden erfolgreich an uns weitergeleitet.'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Your data has been successfully forwarded to us.'
            }
        case GENERAL_FIXATION_CHECK:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Ihre Rückmeldung wurde erfolgreich an uns weitergeleitet.'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Your data has been successfully forwarded to us.'
            }
        case GENERAL_THANKS:
            switch (langCode) {
                case LANGUAGE_CODE_DE:
                    return 'Vielen Dank'
                case LANGUAGE_CODE_EN:
                default:
                    return 'Thank you'
            }
    }
}

/* GENERAL END */