<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered is-medium">
        <p>
          {{ helpText }}
          <a href="tel:+41764340082">076 434 00 82</a>.
        </p>
        <p>© {{ year }} Swiss Domino Effect GmbH.</p>
      </div>
    </div>
  </footer>
</template>
<script>
import { getGeneralText, GENERAL_HELP } from "./pickup_text";
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
    helpText() {
      return getGeneralText(this.$parent.getLanguageCode(), GENERAL_HELP);
    },
  },
};
</script>