<template>
  <div>
    <div v-if="dataPresent">
      <b-tabs type="is-boxed" size="is-medium" v-model="tab_id">
        <b-tab-item key="rents" value="rents">
          <template #header>
            <b-icon icon="package-variant-closed"></b-icon>
            <span>
              {{ textVal("PORTAL_RENT_TAB") }}
              <b-tag rounded> {{ rentsCount }} </b-tag>
            </span>
          </template>
        </b-tab-item>
        <b-tab-item
          label=""
          icon="cog-outline"
          key="settings"
          value="settings"
        ></b-tab-item>
      </b-tabs>
      <section class="content">
        <div v-if="tab_id == 'rents'">
          <b-collapse
            class="card"
            animation="slide"
            v-for="(rent, index) of rents"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
            :aria-id="'contentIdForA11y5-' + index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open"
              >
                <p class="card-header-title" v-html="getRentTitle(rent)" />
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <div
                  class="notification is-danger"
                  v-if="getRentStatus(rent).name == 'S'"
                >
                  <p>
                    Diese Vermietung wurde am
                    {{ getCancellationDate(rent) }} storniert.
                  </p>
                  <p v-if="cancellation_comment(rent)">
                    {{ cancellation_comment(rent) }}
                  </p>
                </div>
                <div v-else>
                  <h4 class="title is-5">{{ textVal("PORTAL_RENT") }}</h4>
                  <div class="control">
                    <b-taglist attached>
                      <b-tag type="is-dark" size="is-medium">Dominos</b-tag>
                      <b-tag type="is-success" size="is-medium"
                        >{{ rent.units / 1000 }}
                        {{ textVal("PORTAL_BOXES") }}</b-tag
                      >
                    </b-taglist>
                    <b-taglist attached>
                      <b-tag type="is-dark" size="is-medium">{{
                        textVal("CONFIRMATION_PICKUP_TIME")
                      }}</b-tag>
                      <b-tag type="is-info" size="is-medium">{{
                        pickup_date_time(rent)
                      }}</b-tag>
                    </b-taglist>
                    <b-taglist attached>
                      <b-tag type="is-dark" size="is-medium">{{
                        textVal("CONFIRMATION_RETURN_TIME")
                      }}</b-tag>
                      <b-tag type="is-info" size="is-medium">{{
                        return_date_time(rent)
                      }}</b-tag>
                    </b-taglist>
                    <div v-if="confirmation_comment(rent)">
                      <b>Bemerkung:</b><br />
                      <p>{{ confirmation_comment(rent) }}</p>
                    </div>
                  </div>
                  <hr />
                  <div class="content is-medium">
                    <b>{{ textVal("PORTAL_CONTACT") }}:</b> {{ rent.name
                    }}<br />
                    <b>{{ textVal("PORTAL_PHONE") }}:&nbsp;</b>
                    <a :href="`tel:${rent.phone}`">{{ rent.phone }}</a>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-else-if="tab_id == 'settings'">
          <PickupPrefs
            :location="messageData.location"
            :pickup_id="project_id"
            :language_code="language"
          />
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { getPortalText, getConfirmationText } from "./pickup_text";
import PickupPrefs from "./PickupPrefs.vue";
export default {
  components: {
    PickupPrefs,
  },
  props: {
    messageData: {
      type: Object,
    },
    project_id: {
      required: true,
      type: String,
    },
  },
  computed: {
    dataPresent() {
      return this.messageData;
    },
    rentsCount() {
      if (this.messageData) {
        return this.messageData.rents.length;
      }
      return 0;
    },
    rents() {
      return this.messageData ? this.messageData.rents : [];
    },
    language() {
      return this.$parent.getLanguageCode()
    }
  },
  data: function () {
    return {
      isOpen: 0,
      tab_id: "rents",
    };
  },
  methods: {
    getRentTitle(rent) {
      let rentStatus = this.getRentStatus(rent);
      return `<span class="tag ${rentStatus.type} is-small">${
        rentStatus.name
      }</span> &nbsp; ${this.getRentDates(rent)} | ${
        rent.units / 1000
      } ${this.textVal("PORTAL_BOXES")}`;
    },
    getRentStatus(rent) {
      let recentMessage = this.mostRecentMessage(rent);
      if (recentMessage.type == "confirmation") {
        return {
          type: "is-success",
          name: "OK",
        };
      } else if (recentMessage.type == "cancellation") {
        return {
          type: "is-danger",
          name: "S",
        };
      }
    },
    getRentDates(rent) {
      let recentConfirmationMsg = this.mostRecentConfirmationMessage(rent);
      if (recentConfirmationMsg) {
        let confirmationData = recentConfirmationMsg.data;
        let pickup_date = confirmationData.pickup_date;
        let return_date = confirmationData.return_date;
        if (pickup_date != return_date) {
          return `${this.convertDate(pickup_date)} - ${this.convertDate(
            return_date
          )}`;
        }
        return this.convertDate(pickup_date);
      }
      return "unknown";
    },
    mostRecentMessage(rent) {
      return rent.messages[rent.messages.length - 1];
    },
    mostRecentConfirmationMessage(rent) {
      let confirmationMessages = rent.messages.filter(
        (msg) => msg.type == "confirmation"
      );
      return confirmationMessages[confirmationMessages.length - 1];
    },
    pickup_date_time(rent) {
      let confirmationData = this.mostRecentConfirmationMessage(rent);
      return `${this.convertDate(confirmationData.data.pickup_date)} ${
        confirmationData.data.pickup_time
      }`;
    },
    return_date_time(rent) {
      let confirmationData = this.mostRecentConfirmationMessage(rent);
      return `${this.convertDate(confirmationData.data.return_date)} ${
        confirmationData.data.return_time
      }`;
    },
    confirmation_comment(rent) {
      let confirmationData = this.mostRecentConfirmationMessage(rent);
      return confirmationData.data.comment;
    },
    cancellation_comment(rent) {
      let cancellationData = this.mostRecentMessage(rent);
      return cancellationData.data.comment;
    },
    getCancellationDate(rent) {
      let cancellationCreation = this.mostRecentMessage(rent).created;
      if (cancellationCreation) {
        return this.convertDate(new Date(cancellationCreation._seconds * 1000));
      }
      return null;
    },
    convertDate(rawString) {
      const dateCode = this.$parent.getDateLocaleCode();
      const dateVal = new Date(rawString);
      return `${dateVal.toLocaleDateString(dateCode, {
        weekday: "long",
      })}, ${dateVal.toLocaleDateString("de-DE")}`;
    },
    refreshData() {
      this.$parent.loadMessageData();
    },
    textVal(textId, params) {
      let textVal = getPortalText(
        this.language,
        textId,
        params
      );
      if (!textVal) {
        textVal = getConfirmationText(
          this.language,
          textId,
          params
        );
      }
      return textVal;
    },
  },
};
</script>