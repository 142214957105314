<template>
  <div>
    <section class="section">
      <div class="container is-max-desktop">
        <h3 class="title is-3">{{ textVal("CONFIRMATION_TITLE") }}</h3>
        <div class="content is-large">
          <p>{{ textVal("CONFIRMATION_SUBTITLE") }}</p>
          <table class="table is-striped">
            <tbody>
              <tr>
                <th>{{ textVal("CONFIRMATION_DOMINO_COUNT") }}</th>
                <td>
                  <b-tag type="is-primary" size="is-large">{{ units }}</b-tag>
                </td>
              </tr>
              <tr>
                <th>{{ textVal("CONFIRMATION_PICKUP_TIME") }}</th>
                <td>
                  <b-tag type="is-success" size="is-large">{{
                    pickup_datetime
                  }}</b-tag>
                </td>
              </tr>
              <tr>
                <th>{{ textVal("CONFIRMATION_RETURN_TIME") }}</th>
                <td>
                  <b-tag type="is-dark" size="is-large">{{
                    return_datetime
                  }}</b-tag>
                </td>
              </tr>
              <tr v-if="pickup_location_formatted">
                <th rowspan="2">{{ textVal("CONFIRMATION_LOCATION") }}</th>
                <td v-html="pickup_location_formatted"></td>
              </tr>
              <tr>
                <td v-if="pickup_location_image">
                  <img :src="pickup_location_image" alt="Pickup Standortbild" />
                </td>
              </tr>
              <tr v-if="pickup_location_video">
                <th>Pickup-Video</th>
                <td>
                  <iframe style="width: 100%; height: 400px;" :src="`https://www.youtube.com/embed/${pickup_location_video}`" title="Pickup video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </td>
              </tr>
              <tr v-if="pickup_location_contact">
                <th>{{ textVal("CONFIRMATION_CONTACT") }}</th>
                <td v-html="pickup_location_contact"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {
  CONFIRMATION_DOMINO_COUNT_VALUE,
  getConfirmationText,
} from "./pickup_text";
import { loadPickupLocationsWithMode } from "@/webapi";
export default {
  props: {
    messageData: {
      required: true,
      type: Object,
    },
    message_id: {
      required: true,
      type: String,
    },
    project_id: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      pickup_location: null,
    };
  },
  async mounted() {
    if (this.pickup_location_id) {
      let response = await loadPickupLocationsWithMode("conf");
      if (response.status == 200) {
        let matchingLocations = response.data.filter(
          (location) => location.id == this.pickup_location_id
        );
        if (matchingLocations.length > 0) {
          this.pickup_location = matchingLocations[0];
          return;
        }
      }
    }
    alert("An error occured.");
  },
  computed: {
    units() {
      let units = this.messageData.data.units;
      return this.textVal(CONFIRMATION_DOMINO_COUNT_VALUE, [
        units,
        units * 1000,
      ]);
    },
    return_datetime() {
      return `${this.formatDate(
        new Date(this.messageData.data.return_date)
      )} - ${this.messageData.data.return_time}`;
    },
    pickup_datetime() {
      return `${this.formatDate(
        new Date(this.messageData.data.pickup_date)
      )} - ${this.messageData.data.pickup_time}`;
    },
    pickup_location_id() {
      return this.messageData.location;
    },
    pickup_location_formatted() {
      if (this.pickup_location) {
        const showInMapText = this.textVal("CONFIRMATION_SHOW_ON_MAP");
        return `${this.pickup_location.location.name}<br>
            ${this.pickup_location.location.street}<br>
            ${this.pickup_location.location.plz} ${this.pickup_location.location.place}<br>
            <a href="${this.pickup_location.location.url}" target="_blank">${showInMapText}</a>`;
      }
      return null;
    },
    pickup_location_contact() {
      if (this.pickup_location) {
        return `${this.pickup_location.name}<br>
        <a href="tel:${this.pickup_location.phone}">${this.pickup_location.phone}</a>`;
      }
      return null;
    },
    pickup_location_image() {
      if (this.pickup_location) {
        return this.pickup_location.location.image_url;
      }
      return null;
    },
    pickup_location_video() {
      if(this.pickup_location) {
        return this.pickup_location.location.video
      }
      return null
    },
    languageCode() {
      return this.$parent.getLanguageCode();
    },
  },
  methods: {
    formatDate(date) {
      const localeIsoCode = this.$parent.getDateLocaleCode(this.languageCode);
      return `${date.toLocaleDateString(localeIsoCode, {
        weekday: "long",
      })}, ${date.toLocaleDateString(localeIsoCode)}`;
    },
    textVal(textId, params) {
      return getConfirmationText(this.languageCode, textId, params);
    },
  },
};
</script>