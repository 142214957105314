<template>
  <div>
    <b-field :label="formattedDate" style="margin-bottom: 20px">
      <b-checkbox-button
        v-model="daySelection"
        v-for="selection in givenSelection"
        :key="selection.val"
        :native-value="selection.val"
        type="is-success"
        size="is-small"
      >
        <span>{{ selection.name }}</span>
      </b-checkbox-button>
      <button class="button is-small is-danger is-light" @click="reset">
        <b-icon icon="close"></b-icon>
      </button>
    </b-field>
  </div>
</template>
<script>
import {
  CHECK_DATE_AFTERNOON,
  CHECK_DATE_EVENING,
  CHECK_DATE_MORNING,
  getCheckDateText,
} from "./pickup_text";
export default {
  props: {
    updateDateEntry: {
      type: Function,
      required: true,
    },
    dateVal: {
      type: Date,
      required: true,
    },
    dateLocaleCode: {
      type: String,
      required: true,
    },
  },
  watch: {
    daySelection(val) {
      this.updateDateEntry(this.dateVal, val);
    },
  },
  data: function () {
    return {
      daySelection: ["morning", "afternoon", "evening"],
      givenSelection: [
        {
          name: "Morgen",
          val: "morning",
        },
        {
          name: "Nachmittag",
          val: "afternoon",
        },
        {
          name: "Abend",
          val: "evening",
        },
      ],
    };
  },
  mounted() {
    if (this.isWeekendDay) {
      // weekend days are not selected by default
      this.daySelection = [];
    }
    const languageCode = this.$parent.languageCode;
    this.givenSelection[0].name = getCheckDateText(
      languageCode,
      CHECK_DATE_MORNING
    );
    this.givenSelection[1].name = getCheckDateText(
      languageCode,
      CHECK_DATE_AFTERNOON
    );
    this.givenSelection[2].name = getCheckDateText(
      languageCode,
      CHECK_DATE_EVENING
    );
  },
  computed: {
    formattedDate() {
      if (this.dateVal) {
        let dayName = this.dateVal.toLocaleDateString(this.dateLocaleCode, {
          weekday: "long",
        });
        let dayFormatted = this.dateVal.toLocaleDateString("de-DE");
        return `${dayName}, ${dayFormatted}`;
      }
      return "";
    },
    isWeekendDay() {
      if (this.dateVal) {
        return this.dateVal.getDay() === 6 || this.dateVal.getDay() === 0;
      }
      return false;
    },
  },
  created() {
    this.updateDateEntry(this.dateVal, this.daySelection);
  },
  methods: {
    reset() {
      this.daySelection = [];
    },
  },
};
</script>