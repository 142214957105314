<template>
  <div>
    <PickupNavigation />
    <PickupPortal
      v-if="is_portal_id"
      :project_id="project_id"
      :messageData="message"
    />
    <div v-else>
      <div v-if="!is_loading && message && !is_finished">
        <Verify
          v-if="message.type == 'verify_number'"
          :messageData="message"
          :project_id="project_id"
          :message_id="message_id"
        />
        <CheckDate
          v-if="message.type == 'date_check'"
          :messageData="message"
          :project_id="project_id"
          :message_id="message_id"
        />
        <FixationCheck
          v-if="message.type == 'fixation_check'"
          :messageData="message"
          :project_id="project_id"
          :message_id="message_id"
        />
        <Confirmation
          v-if="message.type == 'confirmation'"
          :messageData="message"
          :project_id="project_id"
          :message_id="message_id"
        />
      </div>
    </div>
    <Finished v-if="is_finished" :message_type="message.type" />
    <b-loading
      :is-full-page="true"
      v-model="is_loading"
      :can-cancel="false"
    ></b-loading>
    <PickupFooter />
  </div>
</template>
<script>
import PickupNavigation from "./PickupNavigation";
import PickupFooter from "./PickupFooter.vue";
import Verify from "./Verify.vue";
import CheckDate from "./CheckDate.vue";
import Finished from "./Finished.vue";
import FixationCheck from "./FixationCheck.vue";
import PickupPortal from "./PickupPortal.vue";
import Confirmation from "./Confirmation.vue";
import { loadPickupMessage, loadPickupLocations } from "@/webapi";
import { getDateCode } from "./pickup_text";
export default {
  components: {
    PickupNavigation,
    Verify,
    CheckDate,
    PickupFooter,
    Finished,
    FixationCheck,
    PickupPortal,
    Confirmation,
  },
  metaInfo: {
    title: "Pickup",
  },
  data: function () {
    return {
      message: null,
      is_loading: false,
      is_finished: false,
      languageCode: null,
    };
  },
  computed: {
    message_id() {
      return this.$route.params.messageId;
    },
    project_id() {
      return this.$route.params.projectId;
    },
    page_type() {
      if (this.message) {
        return this;
      }
      return null;
    },
    is_portal_id() {
      return this.message_id == "portal";
    },
  },
  created() {
    this.loadMessageData();
  },
  methods: {
    async loadMessageData() {
      this.is_loading = true;
      try {
        if (this.message_id) {
          let messageData = await loadPickupMessage(
            this.project_id,
            this.message_id
          );
          if (messageData.status == 200 && messageData.data.state) {
            this.message = messageData.data.data;
            const pickup_location_id =
              this.message_id == "portal"
                ? this.project_id
                : this.message.location;
            if (await this.loadLanguage(pickup_location_id)) {
              return;
            }
          }
          alert("An error occured. Please try again later.");
        }
      } finally {
        this.is_loading = false;
      }
    },
    async loadLanguage(pickup_location_id) {
      let pickupLocationsResponse = await loadPickupLocations();
      if (
        pickupLocationsResponse.status == 200 &&
        pickupLocationsResponse.data
      ) {
        let matchingLocations = pickupLocationsResponse.data.filter(
          (location) => location.id == pickup_location_id
        );
        if (matchingLocations && matchingLocations.length > 0) {
          const matchingLocation = matchingLocations[0];
          if (matchingLocation) {
            this.languageCode = matchingLocation.location.language;
            return this.languageCode;
          }
        }
      }
      alert("An error occured. Please try again later.");
      return false;
    },
    setFinished() {
      this.is_finished = true;
    },
    getLanguageCode() {
      return this.languageCode;
    },
    getDateLocaleCode() {
      return getDateCode(this.languageCode);
    },
  },
};
</script>